/* You can add global styles to this file, and also import other style files */
/* @import"../node_modules/bootstrap/dist/css/bootstrap.min.css"; */
/* @import"../node_modules/font-awesome/css/font-awesome.min.css"; Josefin+Sans:100,300,400,600,700*/
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,600,700');
@import '~normalize.css';
.parrafo {
   
    color:#747373;
font-size: 18px;
font-weight: 400;
font-size:1em;
text-align:left;

/*text-justify: distribute;*/
text-align-last: left;

margin:0px;
margin-bottom: 20px;
padding:0;
font-family:'Lato',sans-serif;
    }

.header_p{
    font-family:'Lato',sans-serif;
    font-size: 24px;
    font-weight: bold;
    color:#286090;
}
.site-heading h3{
    font-size : 30px;
    margin-bottom: 10px;
    font-weight: 600;
}
.h11 {
    margin: 0;
    padding-top: 0%!important;
    color: hsl(0, 0%, 4%);
    font-family: 'Lato',sans-serif; /*Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;*/
    /* text-transform: uppercase; */
    font-size: 24px;
    line-height: 1;
    margin-bottom: 5px;
    text-align:left;
    word-spacing: normal;
}
.border {
    background: #e8e8e8 ;
    height: 1px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
    float:left;
}
@media (max-width: 767px) {
	.btn-breadcrumb { margin-left: 10px; margin-right: 10px; width: calc(100% - 20px) !important; }
}

.btn-breadcrumb{
	width: 100%;
	background-color: #fff;
	border-radius: 4px;
	border: solid 1px #ccc;
}
.btn-breadcrumb .btn{
	border-color: transparent; border: 0px solid transparent;
	border-right: 1px solid transparent !important; 
	font-size: 11px;
}
.breadcrumb-default{ background-color: #fff; }
.btn-primary,.breadcrumb-primary{ background-color: #337ab7; }
.btn-primary:hover{background-color: #286090;}

.breadcrumb-success{ background-color: #5cb85c; }
.breadcrumb-info{ background-color: #5bc0de; }
.breadcrumb-warning{ background-color: #f0ad4e; }
.breadcrumb-danger{ background-color: #d9534f; }
.breadcrumb-negro{ background-color: #d4d4d4; }

.btn-breadcrumb .btn:last-child {font-weight: bold; text-decoration: underline;}
.btn-breadcrumb .btn:last-child:after{margin-left: -2px;}
.btn-breadcrumb .btn:last-child:before{margin-left: -1px;}

.btn-breadcrumb .btn-derecha{
	float: right;
	margin-right: 0px;
	padding: 6px 10px 6px 10px !important;
	margin-left: 0px !important;
	border-radius: 0px !important; 
}
.btn-breadcrumb .btn-derecha:first-child { 
	border-top-right-radius: 2px !important; 
	border-bottom-right-radius: 2px !important; 
}

.btn-breadcrumb .btn-derecha:after, .btn-breadcrumb .btn-derecha:before{
	content: none !important;
}

.btn-breadcrumb .btn:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 10px solid white;
  position: absolute;
  top: 50%;
  margin-top: -14px;
  margin-left: 0px;
  left: 100%;
  z-index: 3;
}
.btn-breadcrumb .btn:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 10px solid rgb(173, 173, 173);
  position: absolute;
  top: 50%;
  margin-top: -14px;
  margin-left: 1px;
  left: 100%;
  z-index: 3;
}

/** The Spacing **/
.btn-breadcrumb .btn {padding:6px 12px 6px 24px;}
.btn-breadcrumb .btn:first-child {padding:6px 6px 6px 10px;}
.btn-breadcrumb .btn:last-child {padding:6px 18px 6px 24px;}

/** Default button **/
.btn-breadcrumb .btn.btn-default:after { border-left: 10px solid #fff;}
.btn-breadcrumb .btn.btn-default:hover:after {border-left: 10px solid #e6e6e6;}
.btn-breadcrumb .btn.btn-default:hover:before, .btn-breadcrumb .btn.btn-default:before {border-left: 10px solid #adadad;}
.breadcrumb-default{ border: solid 1px #adadad; }

/** Primary button **/
.btn-breadcrumb .btn.btn-primary:after {border-left: 10px solid #337ab7;}
.btn-breadcrumb .btn.btn-primary:hover:after {border-left: 10px solid #286090;}
.btn-breadcrumb .btn.btn-primary:hover:before, .btn-breadcrumb .btn.btn-primary:before {border-left: 10px solid #204d74;}
.breadcrumb-primary{ border: solid 1px #204d74; }

/** Success button **/
.btn-breadcrumb .btn.btn-success:after {border-left: 10px solid #5cb85c;}
.btn-breadcrumb .btn.btn-success:hover:after {border-left: 10px solid #449d44;}
.btn-breadcrumb .btn.btn-success:hover:before, .btn-breadcrumb .btn.btn-success:before {border-left: 10px solid #398439;}
.breadcrumb-success{ border: solid 1px #398439; }

/** Danger button **/
.btn-breadcrumb .btn.btn-danger:after {border-left: 10px solid #d9534f;}
.btn-breadcrumb .btn.btn-danger:hover:after {border-left: 10px solid #c9302c;}
.btn-breadcrumb .btn.btn-danger:hover:before, .btn-breadcrumb .btn.btn-danger:before {border-left: 10px solid #ac2925;}
.breadcrumb-danger{ border: solid 1px #ac2925; }

/** Warning button **/
.btn-breadcrumb .btn.btn-warning:after {border-left: 10px solid #f0ad4e;}
.btn-breadcrumb .btn.btn-warning:hover:after {border-left: 10px solid #ec971f;}
.btn-breadcrumb .btn.btn-warning:hover:before, .btn-breadcrumb .btn.btn-warning:before {border-left: 10px solid #d58512;}
.breadcrumb-warning{ border: solid 1px #d58512; }

/** Info button **/
.btn-breadcrumb .btn.btn-info:after {border-left: 10px solid #5bc0de;}
.btn-breadcrumb .btn.btn-info:hover:after {border-left: 10px solid #31b0d5;}
.btn-breadcrumb .btn.btn-info:hover:before, .btn-breadcrumb .btn.btn-info:before {border-left: 10px solid #269abc;}
.breadcrumb-info{ border: solid 1px #269abc; }



/* buttons*/


/* body{
    background: #f2f2f2;
    font-family: 'Josefin Sans', sans-serif;
} */

/* a , a:hover{
    text-decoration:none;
    text-transform: uppercase;
} */
 
section{
    margin:50px 0px;
}

h1{
    font-family: 'Josefin Sans', sans-serif;
    text-align:center;
    padding:50px 0px;
}

.shadow-btn .btn1{
    text-transform: uppercase;
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    background:#26A65B;
    padding:15px 25px;
    box-shadow: 2px 5px 10px rgba(22, 22, 22, 0.1);
}

.shadow-btn .btn2{
    text-transform: uppercase;
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    background:#FABE58;
    padding:15px 25px;
    box-shadow: inset 0 0 50px 10px rgba(0, 0, 0, 0.8);
}

.shadow-btn .btn2{
    text-transform: uppercase;
    color: #fff;
     border-radius: 4px;
    font-size: 13px;
    background:#FABE58;
    padding:15px 25px;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shadow-btn .btn3{
    text-transform: uppercase;
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    background:#3498DB;
    padding:15px 25px;
    box-shadow: inset 0 0 60px 2px rgba(0, 0, 0, 0.8);
}

.gradient-btn .btn1{
   background: linear-gradient(to right, #00b09b, #96c93d); 
   color: #FFF;
   font-size: 16px;
   padding:25px 60px;
}


.gradient-btn .btn2{
   background-image: linear-gradient(to right, #EC6F66 0%, #F3A183 51%, #EC6F66 100%);
   color: #FFF;
   font-size: 16px;
   padding:25px 60px;
}

.gradient-btn .btn3{
   background-image: linear-gradient(to right, #5f2c82 0%, #49a09d 51%, #5f2c82 100%);
   color: #FFF;
   font-size: 16px;
   padding:25px 70px;
}

.circle-btn .btn1{
    text-transform: uppercase;
    color: #fff;
    border-radius: 50%;
    font-size: 13px;
    background: #AEA8D3;
    padding: 60px 20px;
 }
 
 .circle-btn .btn2{
    text-transform: uppercase;
    color: #fff;
    border-radius: 50%;
    font-size: 13px;
    background: #F62459;
    padding: 60px 25px;
 }
 
 .circle-btn .btn3{
    text-transform: uppercase;
    color: #fff;
    border-radius: 50%;
    font-size: 13px;
    background: #87D37C;
    padding: 60px  45px;
 }
 
.border-btn .btn1{
    text-transform: uppercase;
    color: #FABE58;
    font-size: 13px;
    border:2px solid #FABE58;
    padding:25px 40px;
}

.border-btn .btn1:hover{
    color: #FFF;
    background:#FABE58;
}

.border-btn .btn2{
    text-transform: uppercase;
    color: #4183D7;
    border:2px solid #4183D7;
    font-size: 13px;
    padding:25px 40px;
    box-shadow: 0 2px #4183D7;
}

.border-btn .btn2:hover{
    color: #FFF;
    background:#4183D7;
}

.border-btn .btn3{
    text-transform: uppercase;
    color: #F64747;
    border:2px solid #F64747;
    font-size: 13px;
    padding:25px 55px;
    border-radius:50px;
}

.border-btn .btn3:hover{
    color: #FFF;
    background:#F64747;
}

.border-btn .btn4{
    text-transform: uppercase;
    color: #00E640;
    border:2px solid #00E640;
    font-size: 13px;
    padding:25px 40px;
    border-radius:50px;
}

.border-btn .btn4:hover{
    color: #FFF;
    background:#00E640;
}

.border-btn .btn5{
    text-transform: uppercase;
    color: #BF55EC;
    border:2px solid #BF55EC;
    font-size: 13px;
    padding:25px 40px;
    border-radius: 50%;
    padding: 60px  25px;
}

.border-btn .btn5:hover{
    color: #FFF;
    background:#BF55EC;
}

.border-btn .btn6{
    text-transform: uppercase;
    color: #89C4F4;
    border:2px solid #89C4F4;
    font-size: 13px;
    border-radius: 50%;
    padding: 60px  25px;
}

.border-btn .btn6:hover{
    color: #FFF;
    background:#89C4F4;
}

.effect-btn .btn1{
    text-transform: uppercase;
    color: #4183D7;
    border:2px solid #4183D7;
    font-size: 13px;
    padding:20px 30px;
}

.effect-btn .btn1:hover{
    color: #FFF;
    background:#4183D7;
    padding:25px 30px;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out;
}

.effect-btn .btn2{
    text-transform: uppercase;
    color: #F64747;
    border:2px solid #F64747;
    font-size: 13px;
    padding:20px 35px;
}

.effect-btn .btn2:hover{
    background:#F64747;
    padding:15px 35px;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out;
}

.effect-btn .btn3{
    text-transform: uppercase;
    font-size: 13px;
    padding:20px 45px;
    border:2px solid #BF55EC;
    color: #BF55EC;
    box-shadow: rgba(191, 85, 236, 0.4) 0 0px 0px 2px inset;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -moz-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -ms-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -o-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
}

.effect-btn .btn3:hover{
    color:#FFF;
    box-shadow: rgba(191, 85, 236, 0.7) 0 0px 0px 30px inset;
}

.effect-btn .btn4{
    text-transform: uppercase;
    font-size: 13px;
    padding:20px 45px;
    border:2px solid #00E640;
    color: #00E640;
    box-shadow: rgba(0,230,64,0.4) 0 0px 0px 2px inset;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -moz-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -ms-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -o-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
}

.effect-btn .btn4:hover{
    color:#FFF;
    box-shadow: rgba(0,230,64,0.7) 0 0px 120px 2px inset;
}

.effect-btn .btn5{
    text-transform: uppercase;
    font-size: 13px;
    padding:20px 45px;
    border:2px solid #F9690E;
    color: #F9690E;
    box-shadow: rgba(249, 105, 14,0.4) 0 0px 0px 2px inset;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -moz-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -ms-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -o-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
}

.effect-btn .btn5:hover{
    color:#FFF;
    box-shadow: rgba(249, 105, 14,0.7) 0 55px 0px 2px inset;
}

.effect-btn .btn6{
    text-transform: uppercase;
    font-size: 13px;
    padding:20px 45px;
    border:2px solid #89C4F4;
    color: #89C4F4;
    box-shadow: rgba(137, 196, 244,0.4) 0 0px 0px 2px inset;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -moz-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -ms-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    -o-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
}

.effect-btn .btn6:hover{
    color:#FFF;
    box-shadow: rgba(137, 196, 244,0.7) 170px 0px 0px 2px inset;
}

.section-title {
    text-align: center;
    margin: 30px 0;
    }
    .section-title h2 {
    position: relative;
    display: inline-block;
    padding-bottom: 25px;
    }
    .section-title h2:before {
    position: absolute;
    content: "";
    width: 80px;
    height: 3px;
    background-color: #0bbbc1;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
    }
    .section-title h2:after {
    position: absolute;
    content: "";
    width: 60px;
    height: 3px;
    background-color: #0bbbc1;
    left: 50%;
    bottom: -10px;
    margin-left: -30px;
    }


    /*buttons*/
    effect-btn .btn2:hover{
        background:#F64747;
        padding:15px 35px;
        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;
        -o-transition: all 1s ease-out;
        transition: all 1s ease-out;
    }
    
    .effect-btn .btn3{
        text-transform: uppercase;
        font-size: 13px;
        padding:20px 45px;
        border:2px solid #BF55EC;
        color: #BF55EC;
        box-shadow: rgba(191, 85, 236, 0.4) 0 0px 0px 2px inset;
        -webkit-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
        -moz-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
        -ms-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
        -o-transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
        transition: all 300ms cubic-bezier(0.4, 0.500, 0.5, 1.5);
    }
    
    .effect-btn .btn3:hover{
        color:#FFF;
        box-shadow: rgba(191, 85, 236, 0.7) 0 0px 0px 30px inset;
    }
    .gradient-btn .btn3{
        background-image: linear-gradient(to right, #5f2c82 0%, #49a09d 51%, #5f2c82 100%);
        color: #FFF;
        font-size: 16px;
        padding:25px 70px;
     }
     .border-btn .btn3{
         
        text-transform: uppercase;
        color: #F64747;
        border:2px solid #F64747;
        font-size: 13px;
        padding:25px 55px;
        border-radius:50px;
    }
    
    .border-btn .btn3:hover{
        color: #FFF;
        background:#F64747;
    }